<template>
    <div class="hello">
      <el-dialog
        title="关联设备"
        :visible.sync="dialogVisible"
        width="1000px"
        :close-on-click-modal="false"
        :before-close="dialogClose"
      >
        <p class="store-name">{{storeName}}</p>
        <el-table
            border
            :header-cell-style="headerCellStyle"
            v-loading="loading"
            :cell-style="cellStyle"
            :data="tableData"
            max-height="700px"
            style="width: 100%">
            <el-table-column
                prop="device.deviceTypeName"
                label="设备类型"
                >
            </el-table-column>
            <el-table-column
                prop="device.deviceName"
                label="设备型号"
                >
            </el-table-column>
            <el-table-column
                prop="statusDes"
                min-width="150"
                label="设备状态">
                <template #default="{row}">
                    <div>
                        {{ row.statusDes }}
                    </div>
                    <div v-if="row.workinfo" class="run-time">
                        ({{row.workinfo?.predictEnd}})
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="statusTime"
                label="当前状态更新时间">
            </el-table-column>
            <el-table-column
                prop="lastStatusTime"
                label="上次状态更新时间">
            </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { Component, PropSync, Prop, Watch, Vue } from "vue-property-decorator";
  import { getDeviceLoadWithStatus } from "@/api/store";
  
  @Component
  export default class AssociatedDevice extends Vue {
    @PropSync("dialogVisible") visible;
    @Prop() storeId;
    @Prop() storeName;

    tableData = [];
    loading = false;

    dialogClose() {
        this.visible = false;
    }

    // 表头样式
    headerCellStyle() {
        return {
        background: '#F8F9FA', color: '#1A1A1C', textAlign: 'center'
        }
    }
    // 列表样式
    cellStyle() {
        return {
        textAlign: 'center'
        }
    }

    @Watch("visible")
    getDeviceStatusList(value) {
        if (value) {
            this.loading = true;
            this.tableData = [];
            getDeviceLoadWithStatus({ storeId: this.storeId }).then((res) => {
                this.tableData = res.data;
            }).finally(() => {
                this.loading = false;
            })
        }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .store-name{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .run-time{
    color: orange;
  }
  </style>
  