<template>
    <el-dialog
        :title="storeDetail?.storeName"
        :visible.sync="visible"
        width="700px"
        :close-on-click-modal="false"
        :before-close="dialogClose"
      >
      <el-descriptions title="门店信息" :column="2">
        <el-descriptions-item label="门店类型">{{ handleColumnValue('storeType', storeDetail?.storeType) }}</el-descriptions-item>
        <el-descriptions-item label="门店状态">{{ storeDetail.statusDesc }}</el-descriptions-item>
        <el-descriptions-item label="休息日期" span="2">
            <div>
                <el-tag class="tag-one" v-for="item in storeDetail?.restDateList" :key="item.id" type="info">
                    {{formatDate(item)}}
                </el-tag>
            </div>
        </el-descriptions-item>

        <el-descriptions-item label="门店名称">{{storeDetail?.storeName ?? "-"}}</el-descriptions-item>
        <el-descriptions-item label="门店外显名称">{{storeDetail?.storeShowName ?? "-"}}</el-descriptions-item>

        <el-descriptions-item label="门店电话">{{storeDetail?.storePhone ?? "-"}}</el-descriptions-item>
        <el-descriptions-item label="门店负责人">{{storeDetail?.ownerName ?? "-"}}</el-descriptions-item>

        <el-descriptions-item label="关联管理员" span="2">
            <div>
                <el-tag class="tag-one" v-for="item in storeDetail?.storeUserOwners" :key="item.id" type="info">
                    {{item.phone ?? "-"}}
                </el-tag>
            </div>
        </el-descriptions-item>
        <el-descriptions-item label="门店店员" span="2">
            <div>
                <el-tag class="tag-one" v-for="item in storeDetail?.storeMemberList" :key="item.id" type="info">
                    {{item.phone ?? "-"}}
                </el-tag>
            </div>
        </el-descriptions-item>

        <el-descriptions-item label="联系方式">{{storeDetail?.phone??"-"}}</el-descriptions-item>
        <el-descriptions-item label="门店城市">{{storeDetail?.addressDesc??"-"}}</el-descriptions-item>

        <el-descriptions-item label="门店具体地址" span="2">{{storeDetail?.shipAddress??"-"}}</el-descriptions-item>

        <el-descriptions-item label="门店经纬度">{{`${storeDetail?.storeLat},${storeDetail?.storeLong}`}}</el-descriptions-item>
        {{storeDetail?.storeMentor}}
        <el-descriptions-item label="门店督导">{{storeDetail?.storeMentorUser?.nickname??"-"}}：{{storeDetail?.storeMentorUser?.phone?? "-"}}</el-descriptions-item>

        <el-descriptions-item label="服务跟进">{{storeDetail?.storeSupportUser?.nickname ?? "-"}}：{{storeDetail?.storeSupportUser?.phone?? "-"}}</el-descriptions-item>
        <el-descriptions-item label="SaaS系统绑定">{{storeDetail?.saasStoreName??"-"}}</el-descriptions-item>

        <el-descriptions-item label="关联设备" span="2">
            <div>
                <el-tag class="tag-one" v-for="item in storeDetail?.storeDevice" :key="item.id" type="info">
                    {{item?.deviceName||"-"}}
                </el-tag>
            </div>
        </el-descriptions-item>

        <el-descriptions-item label="备注" span="2">{{storeDetail?.storeInfo??"-"}}</el-descriptions-item>

      </el-descriptions>
      <el-descriptions title="服务信息" :column="2">
        <el-descriptions-item label="银联商户号">{{storeDetail?.mid || "-"}}</el-descriptions-item>
        <el-descriptions-item label="终端号">{{storeDetail?.tid || "-"}}</el-descriptions-item>
        <el-descriptions-item label="待付商户号">{{storeDetail?.mchntNo || "-"}}</el-descriptions-item>
        <el-descriptions-item label="服务状态">{{storeDetail.mallEnable ? "启用" : "禁用"}}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
</template>
<script>
  import { Component, PropSync, Prop, Vue } from "vue-property-decorator";
  import dayjs from "dayjs";
  
  @Component
  export default class StoreDetail extends Vue {
    @Prop() storeDetail;
    @Prop() storeTypeList;
    @PropSync("storeDetailVisible") visible;

    dialogClose() {
        this.visible = false;
    }

    formatDate(date) {
      return dayjs(date).format("MM-DD")
    }

    handleColumnValue(type, val) {
    if (type == 'default') {
      return val ? val : '-';
    } else if (type == 'storeType') {
      let typeName = '-';
      for (let item of this.storeTypeList) {
        if (item.val == val) {
          typeName = item.name;
          break;
        }
      }
      return typeName;
    }
    return val;
  }
  }

</script>
<style lang="scss" scoped>
.tag-one{
    margin-right: 5px;
    margin-bottom: 5px;
}
.tag-one2{
    margin-right: 5px;
    margin-bottom: 5px;
}
.label{

}
.value{

}
</style>